.bes-text-area,
.bes-input {
  @extend .bes-bg-color-clean;
  @extend .w-full;
  @extend .bes-h-40;

  border: 1px solid $clean; 
  border-radius: 2px;
  color: $black;

  &:focus-visible {
    outline: none;
  }

  &:disabled {
    opacity: 0.5;
  }
}

