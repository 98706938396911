.home-mobile-container {
    background-image: url('../../project/background-mobile.png');
    background-size: cover;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.home-mobile-container .logotipo img {
    margin-top: 120px;
    width: 200px;
}

.start-title h1 {
    font: normal normal 900 25px/32px 'alegreyasans-black';
}

.start-btn-cadastro button {
    width: 150px;
    background: transparent linear-gradient(254deg, #46CEFF 0%, #48E686 100%) 0% 0% no-repeat padding-box;
}

.start-btn-login button {
    width: 150px;
    background: transparent linear-gradient(254deg, #C149F4 0%, #6B6CF5 100%) 0% 0% no-repeat padding-box;
}