@media (min-width: 768px) {
    .perfil-container {
        padding: 50px 90px;
    }
}

@media (max-width: 768px) {
    .perfil-container {
        padding: 30px 10px;
    }
}

.perfil-container {
    margin-top: 60px;
    background: #241C35 0% 0% no-repeat padding-box;
    border: 3px solid #646AEA;
    border-radius: 30px;
}

.titulo-perfil-container h1 {
    font: normal normal 900 25px 'Alegreyasans-Black';
}

.group-perfil {
    gap: 5px;
}

.group-perfil-input {
    width: 100%;
    color: white;
}

.group-perfil-btn {
    background: transparent linear-gradient(264deg, #46CEFF 0%, #46D3E7 20%, #48E784 100%) 0% 0% no-repeat padding-box;
    width: 100%;
}

.group-perfil p {
    color: #FFFFFF5C;
}

.ambosSelect {
    width: 100%;
    height: 49px;
    margin-top: 9px;

    background-color: transparent;
    border: 1px solid #298780;
    border-radius: 10px;
}

.ambosSelect ul {
    background-color: #646aea;
    // border: 3px solid #7e65b6;
    border-radius: 10px;
}

.ambosSelect ul li span {
    background-color: #7e65b6
}

.ambosSelect ul li span:hover {
    background-color: #241C35;
}

.ambosSelect .p-drop-panel {
    border-radius: 10px;
    background-color: #7e65b6;
}

.ambosSelect .p-dropdown-panel {
    background-color: #7e65b6;
    border-radius: 30px;
}

.ambosSelect .p-dropdown-items-wrapper {
    border-radius: 10px;
}

.ambosSelect .p-dropdown-item:hover {
    background-color: #241C35;
}

.ambosSelect span {
    display: flex;
    align-items: center;
    font-size: 15px;
    padding-left: 10px;
    color: white;
}

.ambosSelect .p-dropdown-label {
    color: white;
}

// styles para perfil
.menu-lateral-perfil {
    background-color: transparent;
    border: none;
    width: 100%;
}

.menu-lateral-perfil a span {
    color: white;
}

.menu-lateral-perfil a {
    text-decoration: none;
    justify-content: start;
}

.menu-lateral-perfil a:hover {
    background-color: #4b3367;
}