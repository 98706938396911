.maquina-card {
  
  &:hover {
    cursor: pointer;
  }

    .borda-quadrada-externa {
        border-bottom: 20px solid transparent;
        border-left: 20px solid $tertiary;
    }
    .borda-quadrada-interna {
        border-bottom: 20px solid transparent;
        border-left: 20px solid $primary;
    }

    .content-top {
        border-radius: 15px 15px 0px 0px;
    }
    .content-bottom {
        border-radius: 0px 0px 0px 15px;
    }

    .semaforo i {
        color: #ef4444;
    }

}
