.mobile-drawer-account {
    background: linear-gradient(180deg, #413e96, #302e6d);
}

.mobile-drawer-account .row {
    display: flex;
    justify-content: center;
}

.drawer-account-option {
    width: 80%;
    border-bottom: 1px solid rgb(255, 255, 255);
    padding: 0;
    font: normal normal 900 15px/32px 'Alegreya Sans';
}
