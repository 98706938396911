.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

.borda-bottom{
  border-bottom: 30px solid transparent;
}
.borda-left{
  border-left: 50px solid white;
}
.borda-top{
  border-top: 30px solid transparent;
}

.texto {
  width: 100%;
  height: 100px;
}
.p-button-label{
  font-family: 'AlegreyaSans-Black';
}

// .margin-top1{
//   margin-top: 130px;
// }