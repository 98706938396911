:host {
  display: block;
  margin: 20px;
}

.backgroundTable {
  background-color: #452f60;
}

.custom-sorticon .p-sorticon {
    color: #FF5733; 
  }

p-table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;

  tr.tabela-tr:hover {
    background-color: #584f79 !important;
  }

  tr.cabecalho th {
    background-color: #1f1d2b;
    transition: background-color 0.3s, color 0.3s;
  }


  tr.cabecalho th:hover {
    background-color: #252231;
  }

  .p-datatable-thead th {
    padding: 10px;
    text-align: left;
    color: #ffffff;
  }

  .p-datatable-tbody {
    tr:nth-child(odd) {
      background-color: #452f60;
    }

    tr:nth-child(even) {
      background-color: #452f60;
    }

    td {
      padding: 10px;
      border-bottom: 1px solid #1f1d2b;
      color: #ffffff;
    }
  }

  .p-paginator {
    margin-bottom: 15px;
    padding: 10px;
    background-color: #1f1d2b;
    text-align: right;
    border-bottom: none;
  }

  .p-paginator .p-paginator-page {
    color: #ffffff;
    border-radius: 50%;
    border: 1px solid transparent;
    background-color: #452f60;
    height: 47px;
    width: 47px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }

  .p-paginator .p-paginator-page:hover {
    background-color: #3b2852;
    border: 1px solid #4ae9a9;
  }

  .p-paginator .p-paginator-page.p-highlight {
    background-color: #3b2852;
    color: #ffffff;
    border: 1px solid #4ae9a9;
    font-weight: bold;
  }

  .p-paginator .p-paginator-prev .p-icon,
  .p-paginator .p-paginator-next .p-icon,
  .p-paginator .p-paginator-first .p-icon,
  .p-paginator .p-paginator-last .p-icon {
    color: #4ae9a9;
    transition: color 0.3s;
  }
}
