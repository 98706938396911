.sidebar-container {
  .sidebar-body {
    .menu {
      .conteudo {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        &-m1 {
          padding: 0px 10px;
        }
        &-m2 {
          padding: 0px 5px 0px 13px;
        }
        &-m3 {
          padding: 0px 0px 0px 20px;
        }

        &:hover,
        &-active {
          background-color: $clean;
          color: $primary;
        }
      }

      &.active {
        border-left: 5px solid $secondary-dark;
        .conteudo-m1 {
          padding: 0px 5px;
        }
      }
    }
  }
}

// p-focus do side-menu
.p-tieredmenu
  .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
  > .p-menuitem-content {
  background-color: transparent;
}

// MODAL URSINHO TESTE

@media screen and (max-width: 400px) {
  .modal-ganhou {
    width: 110vw !important;
  }

  .into-modal-ganhou {
    left: 47% !important;
  }
}

.modal-ganhou {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 2;

  background-image: url(../../project/Popup.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.into-modal-ganhou {
  height: 300px;
  width: 300px;
  position: absolute;
  top: 57%;
  left: 49%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;
}

// .img-modal-ganhou {  Usar caso seja requerida uma imagem solta
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .modal-ganhou img {
//   width: 1600px;
// }

// modal-ganhou
path {
  fill: #8d3aef !important;
}

.svg-parabens {
  height: 75px;
}

.dis-title {
  font-family: "AlegreyaSans-Black";
}

.dis-title-text {
  font-size: 75px;
  fill: white;
}

.modal-ganhou path {
  fill: transparent !important;
}

// botão close de modal-pay
.modal-pay-style path {
  fill: #00d3e2;
}
.modal-pay-style button[type="button"] {
  border: 1px solid #00d3e2;
}
