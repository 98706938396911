h1,
h2,
h3,
h4,
h5,
h6,
p,
label,
span,
strong {
  margin: 0;
  padding: 0;
  font-family: "Poppins-Regular";
}
