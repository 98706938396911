.painel > div {
    position: absolute;
}

.painel p {
    text-align: center;
    font: normal normal 900 30px 'Alegreyasans-Black';
}

.carousel li > button {
    background-color: #646AEA;
    height: 8px;
    margin: 0px 5px;
}

.carousel li > button:active, .carousel li > button:focus {
    background-color: #969ae6;
}