.gradient-tertiary-clean {
  background: transparent linear-gradient(243deg, #4ADAFF 0%, #4BEAB0 100%) 0% 0% no-repeat padding-box !important;
}

.p-accordion-header{
  margin-top: 15px;

}
.p-accordion-header-text{
    font-family: 'AlegreyaSans-Black';
    color: #452F60;
    font-size: 15px;
}

.p-accordion-header-link{
  border: 1px solid;
  border-color: #452F60;
  border-radius: 3px;
  text-decoration: none;
  background-color: transparent;

  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding-right: 30px;
}

.p-icon{
    color: #452F60;
}

.p-accordion-content{
    border: 1px solid;
    border-top: 0px;
    border-color: #452F60;
    border-radius: 3px;
    color: #452F60;
    background-color: transparent;
}






// Estilização de painel imagem produto
.imagem-produto span {
  width: auto;
}

.content-image-produto {
  border: 3px solid #6f4ff1;
  border-radius: 10px;
  width: 200px;
  height: 100px;
  z-index: 1;
}

.content-image-produto img {
  border-radius: 10px;
}