@font-face {
  font-family: "Nunito-Black";
  src: local("Nunito-Black"), url("Nunito-Black.ttf") format("truetype");
}
@font-face {
  font-family: "Nunito-BlackItalic";
  src: local("Nunito-BlackItalic"),
    url("Nunito-BlackItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Nunito-Bold";
  src: local("Nunito-Bold"), url("Nunito-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Nunito-BoldItalic";
  src: local("Nunito-BoldItalic"),
    url("Nunito-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Nunito-ExtraBold";
  src: local("Nunito-ExtraBold"), url("Nunito-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "Nunito-ExtraBoldItalic";
  src: local("Nunito-ExtraBoldItalic"),
    url("Nunito-ExtraBoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Nunito-ExtraLight";
  src: local("Nunito-ExtraLight"),
    url("Nunito-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "Nunito-ExtraLightItalic";
  src: local("Nunito-ExtraLightItalic"),
    url("Nunito-ExtraLightItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Nunito-Italic";
  src: local("Nunito-Italic"), url("Nunito-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Nunito-Light";
  src: local("Nunito-Light"), url("Nunito-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Nunito-LightItalic";
  src: local("Nunito-LightItalic"),
    url("Nunito-LightItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Nunito-Medium";
  src: local("Nunito-Medium"), url("Nunito-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Nunito-MediumItalic";
  src: local("Nunito-MediumItalic"),
    url("Nunito-MediumItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Nunito-Regular";
  src: local("Nunito-Regular"), url("Nunito-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Nunito-SemiBold";
  src: local("Nunito-SemiBold"), url("Nunito-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Nunito-SemiBoldItalic";
  src: local("Nunito-SemiBoldItalic"),
    url("Nunito-SemiBoldItalic.ttf") format("truetype");
}

.nunito-black {
  font-family: "Nunito-Black", Arial, Helvetica, sans-serif;
}

.nunito-blackitalic {
  font-family: "Nunito-BlackItalic", Arial, Helvetica, sans-serif;
}

.nunito-bold {
  font-family: "Nunito-Bold", Arial, Helvetica, sans-serif;
}

.nunito-bolditalic {
  font-family: "Nunito-BoldItalic", Arial, Helvetica, sans-serif;
}

.nunito-extrabold {
  font-family: "Nunito-ExtraBold", Arial, Helvetica, sans-serif;
}

.nunito-extrabolditalic {
  font-family: "Nunito-ExtraBoldItalic", Arial, Helvetica, sans-serif;
}

.nunito-extralight {
  font-family: "Nunito-ExtraLight", Arial, Helvetica, sans-serif;
}

.nunito-extralightitalic {
  font-family: "Nunito-ExtraLightItalic", Arial, Helvetica, sans-serif;
}

.nunito-italic {
  font-family: "Nunito-Italic", Arial, Helvetica, sans-serif;
}

.nunito-light {
  font-family: "Nunito-Light", Arial, Helvetica, sans-serif;
}

.nunito-lightitalic {
  font-family: "Nunito-LightItalic", Arial, Helvetica, sans-serif;
}

.nunito-medium {
  font-family: "Nunito-Medium", Arial, Helvetica, sans-serif;
}

.nunito-mediumitalic {
  font-family: "Nunito-MediumItalic", Arial, Helvetica, sans-serif;
}

.nunito-regular {
  font-family: "Nunito-Regular", Arial, Helvetica, sans-serif;
}

.nunito-semibold {
  font-family: "Nunito-SemiBold", Arial, Helvetica, sans-serif;
}

.nunito-semibolditalic {
  font-family: "Nunito-SemiBoldItalic", Arial, Helvetica, sans-serif;
}
