.modal-jogo-style {
  border: none !important; // fix bug borda
}

.modal-jogo-style .p-element {
  width: 40rem !important;
  border-radius: 22px;
  border: 2px solid #646aea;
}

.modal-jogo-style .p-dialog-header {
  border-radius: 20px 20px 0px 0px !important;
}

.modal-jogo-style .p-dialog-content {
  border-radius: 0px 0px 20px 20px !important;
}

.modal-jogo-style .p-dialog-title {
  font-size: 42px;
  margin-top: 30px;
}

.modal-jogo-style button.p-element {
  display: none;
}
//viewPort

.full-height-img {
  height: 69vh;
}
.full-height-game {
  height: 69vh;
  @media only screen and (min-width: 600px) {
    height: 51vh;
  }
}

// .full-height-control {
//     height: 42vh;
// }

//botoes

.hover-btn-play {
  &:hover {
    cursor: pointer;
  }
}

.modal-jogo-style .btn-jogo {
  background: transparent linear-gradient(224deg, #8f3bf1 0%, #fa5af8 100%) 0%
    0% no-repeat padding-box;
  mix-blend-mode: normal;
  color: white;
  opacity: 1;
  font-family: "AlegreyaSans-Black";
  width: 80%;
  border: transparent;
  padding: 10px;
  border-radius: 10px;
  height: 50px;
}

.modal-jogo-style .btn-play {
  // background: transparent linear-gradient(224deg, #00CEFF 0%, #00FF0A 100%) 0% 0% no-repeat padding-box;
  background: transparent linear-gradient(224deg, #00ceff 0%, #00ff9d 100%) 0%
    0% no-repeat padding-box;
  mix-blend-mode: normal;
  color: white;
  opacity: 1;
  font-family: "AlegreyaSans-Black";
  width: 80%;
  border: transparent;
  padding: 10px;
  border-radius: 10px;
  height: 50px;
}

//controladores do jogo

.controlador {
  cursor: pointer; /* Muda o cursor para uma mão ao passar sobre a imagem */
  transition: box-shadow 0.2s ease, transform 0.2s ease; /* Suaviza a transição da sombra e da transformação */
  border-radius: 50%;
}

/* Estilo para a imagem quando está sendo clicada */
.controlador:active {
  transform: scale(
    0.7
  ); /* Reduz o tamanho da imagem um pouco para criar um efeito de "pressionar" */
  background-color: #707070;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Sombra mais escura ao clicar */
}

.controlador-mobile {
  cursor: pointer;
  transition: box-shadow 0.2s ease, transform 0.2s ease;
  border-radius: 50%;
}
.controlador-mobile:active {
  transform: scale(0.7);
  background-color: #816ef5;
}
