// modal-perfil

.modal-header-perfil {
    background-color: #291F3E;
    border: 3px solid #646AEA;
    width: auto;
}

.modal-header-perfil ul {
    width: 210px;
}

.modal-header-perfil a span {
    color: white;
}

.modal-header-perfil a {
    text-decoration: none;
    justify-content: start;
}

.modal-header-perfil a:hover {
    background-color: #4b3367;
}

.logotipo-footer {
    width: 200px;
    margin-bottom: 20px;
}

.btn-bonus {
    width: 100%;
    padding: 0;
}
