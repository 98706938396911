@media (min-width: 768px) {
    .legal-container {
        padding: 50px 90px;
    }
}

@media (max-width: 768px) {
    .legal-container {
        padding: 30px 10px;
    }
}

.legal-container {
    margin-top: 60px;
    background: #241C35 0% 0% no-repeat padding-box;
    border: 3px solid #646AEA;
    border-radius: 30px;
}

.legal-container h1 {
    font: normal normal 900 30px 'Alegreyasans-Black';
}

.legal-container p, .legal-container li {
    text-align: justify;
}