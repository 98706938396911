.bes-button {
  border-radius: 3px;
}



.botao-jogar {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.botao-aguarde {
  filter: brightness(0.7);
}


// Styles para buttons-display

.buttons-display {
  width: 180px;
  height: 180px;
  margin: 0;
  padding: 0;
  
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.buttons-display > div {
  width: 180px;
}

.up-btn, .left-btn, .right-btn, .down-btn {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.left-btn {
  display: flex;
  justify-content: start;
}

.right-btn {
  display: flex;
  justify-content: end;
}

.controlador-mobile-img {
  pointer-events: none;
}

.btn-controlador {
  border: none;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  pointer-events: none;
}
