$total-size: 500;
$sizes: (
  "": 0px,
  "-md": 768px,
);
$sufixs: (
  "px": "",
  "%": "-perc",
);
@each $size, $sizevalue in $sizes {
  @for $i from 0 through $total-size {
    @each $sufix, $sufixvalue in $sufixs {
      @media (min-width: $sizevalue) {
        .bes-h#{"" + $size}-#{$i}#{"" + $sufixvalue} {
          height: #{$i}#{$sufix};
        }
        .bes-lh#{"" + $size}-#{$i}#{"" + $sufixvalue} {
          line-height: #{$i}#{$sufix};
        }
        .bes-max-h#{"" + $size}-#{$i}#{"" + $sufixvalue} {
          max-height: #{$i}#{$sufix};
        }
        .bes-min-h#{"" + $size}-#{$i}#{"" + $sufixvalue} {
          min-height: #{$i}#{$sufix};
        }
        .bes-w#{"" + $size}-#{$i}#{"" + $sufixvalue} {
          width: #{$i}#{$sufix};
        }
        .bes-wper#{"-perc" + $size}-#{$i}#{"-perc" + $sufixvalue} {
          width: #{$i}#{$sufix};
        }
        .bes-fs#{"" + $size}-#{$i}#{"" + $sufixvalue} {
          font-size: #{$i}#{$sufix};
          line-height: #{$i}#{$sufix};
        }
        .bes-top#{"" + $size}-#{$i}#{"" + $sufixvalue} {
          top: #{$i}#{$sufix};
        }
        .bes-right#{"" + $size}-#{$i}#{"" + $sufixvalue} {
          right: #{$i}#{$sufix};
        }
        .bes-left#{"" + $size}-#{$i}#{"" + $sufixvalue} {
          left: #{$i}#{$sufix};
        }
        .bes-bottom#{"" + $size}-#{$i}#{"" + $sufixvalue} {
          bottom: #{$i}#{$sufix};
        }
        .bes-border-radius#{"" + $size}-#{$i}#{"" + $sufixvalue} {
          border-radius: #{$i}#{$sufix};
        }
      }
    }
  }
}