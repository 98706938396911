.background-card {
    width: 150px;
    height: 120px;
    background-color: #4B8FEF;

    transform: skewX(-3.2deg) skewY(-3.2deg);
    background: #4FF3CF;
}

.surface-card {
    width: 143px;
    height: 111px;
    background-color: #201D2B;
    position: absolute;
    border: 2px solid #4B8FEF;
}

.background-card:hover, .surface-card:hover {
    cursor: pointer;
}

.moedas-icon {
    width: 40px;
}

.qtd-fichas p {
    font: normal normal 900 30px/30px 'Alegreya Sans';
}