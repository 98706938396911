// .bes-modal {
//   &.bes-modal-background {
//     position: fixed;
//     background-color: rgba(0, 0, 0, 0.7);
//     width: 100vw;
//     height: 100vh;
//     top: 0;
//     left: 0;
//     padding: 0;
//     margin: 0;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     z-index: 4;

//     .bes-modal-box {
//       min-width: 250px;
//       min-height: 150px;
//       width: 150px;
//       background-color: $clean;
//       border-radius: 20px;
//     }
//   }
// }

// background-color: #241C35;

.box-modal {
  width: 20rem !important;
  border: 3px solid #6f4ff1;
  border-radius: 46px;
}

.box-modal .p-dialog-header {
  display: flex;
  justify-content: center;
  background-color: #241C35;
  border-radius: 42px 42px 0 0;
  padding-top: 40px;

  flex-direction: column-reverse;
}

.p-dialog-header button {
  position: absolute;
  right: 30px;
  border: 1px solid #8D3AEF !important;
  border-radius: 30px;
  height: 20px;
  width: 20px;
  padding: 0px;
  margin: 0px;
}

.p-dialog-header svg {
  height: 10px;
  font-family: 'AlegreyaSans-Black';
}

.p-dialog-header span {
  font-size: 30px;
  font-family: 'AlegreyaSans-Black';
}

.p-dialog-header button path {
  color: #8D3AEF;
}

.box-modal .p-dialog-content:last-of-type {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  background-color: #241C35;
  border-radius: 0 0 42px 42px;
}

.inputEmail, .inputSenha {
  padding-left: 20px;
  color: white;
  outline: none;
}

.inputEmail:active, .inputSenha:active {
  border: 1px solid #298780;
}

input, .btn-login {
  width: 80%;
  border: 1px solid #298780;
  padding: 10px;
}

input, button, select, optgroup, textarea {
  border-radius: 10px;
  height: 50px;
  background: transparent;
}

.inputEmail::placeholder, .inputSenha::placeholder, .p-dialog-header span {
  color: white;
}

.btn-login {
  background: transparent linear-gradient(260deg, #C149F4 0%, #6B6CF5 100%) 0% 0% no-repeat padding-box;
  color: white;
  font-family: 'AlegreyaSans-Black';
}

.loginAlt, .forgot {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loginAlt span, .forgot p {
  color: white;
}

.forgot span {
  color: #4AE9AA;
}

.checkboxes-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
}

.checkboxes {
  display: flex;
  width: 80%;
  height: 30px;
  align-items: center;
  gap: 5px;
}

.checkboxes p, .terms-politics p {
  font-size: 10px;
  color: white;
}

.checkboxes span, .terms-politics span {
  color: #4AE9AA;
}

input[type="checkbox"] {
  width: 12px;
  height: 12px;
}


.login-title {
  margin-top: 50px;
}

.login-title h1 {
  font: normal normal 900 30px/48px 'AlegreyaSans-black';
}

toy-page-login-mobile, toy-page-cadastro-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

toy-login, toy-cadastro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 80%;
}

.inputEmail, toy-login button, .inputSenha, toy-cadastro button {
  width: 100% !important;
}

.back-btn {
  display: flex;
  justify-content: start;
  width: 100%;
}

.back-btn i {
  font-size: 2rem !important;
}

.forgot .alt-pswrd {
  color: #4AE9AA;
}

.terms-politics {
  position: absolute;
  bottom: 0;
  padding-bottom: 20px;
}




// modal-pay-style
.modal-pay-style div.p-element {
  height: 650px;
  width: 450px !important;
  border: 3px solid #6f4ff1;
  border-radius: 46px;
}

// modal-saque-style
.modal-saque-style div.p-element {
  height: 520px;
  width: 400px !important;
  border: 3px solid #6f4ff1;
  border-radius: 46px;
}

.modal-saque-style input {
  color: white;
}


// modal-vender
.modal-vender {
  width: 400px !important;
}

.vender-button {
  border-radius: 50px;
  width: 100px;
  height: 100px;
  border-style: inset;
  border-color: #ffe600;
}


// modal-painel-produto
.modal-painel-produto div.p-element {
  height: 280px;
  width: 450px !important;
  border: 3px solid #6f4ff1;
  border-radius: 46px;
}
 